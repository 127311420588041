body {
    margin: 16px;
}

.logo {
    max-width: 80vw;
    min-width: 20vw;
    fill: #3498db;
    min-height: 20vh;
}

h1 {
    margin: 0
}